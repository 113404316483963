.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cursor-pointer {
  cursor: pointer;
}

.App-loading {
  height: 100vh;
}

.MuiTypography-root{
  color: #ef8157;
}

.MuiTypography-colorPrimary{
  color: #343a40 !important;
}

.MuiButton-containedPrimary{
  background-color: #ef8157 !important;
}

.MuiFormLabel-root.Mui-focused{
  color: #ef8157 !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: #ef8157 !important;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
  border-color: #f44336 !important;
}

.MuiListItemText-secondary{
  color: #fff !important;
}
